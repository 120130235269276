import Collapse from "components/Collapse"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"

function convertLinkAms(url) {
  const project_id = localStorage.getItem("project_id")
  return `${process.env.REACT_APP_AMS_CMS_URL}${url}?project_id=${project_id}`
}

export default function Navbar({ items, isNav }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  // const project_id = localStorage.getItem("project_id")

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    }
    return () => {
      document.body.style.overflow = null
      document.body.style.width = null
    }
  }, [isNav])
  const wapperClass = isNav ? "show__sider" : "hide__sider"
  return (
    <div
      className={`__bg z-10 sticky text-slate-200 h-screen border-r border-slate-700 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={`gap-3 border-b justify-center py-3 border-slate-700 px-6 h-14 flex text-xl font-bold items-center text-slate-50`}
      >
        <img
          src={isNav ? "/logo.png" : "/logo-small.png"}
          alt="logo"
          className={`${isNav ? "h-full" : "h-4/5"} cursor-pointer logo`}
          onClick={() => navigate(`/project_overview`)}
        />
      </div>
      {isNav && (
        <div className="px-2">
          <Link to="/">
            <div className="bg-gray-100 p-2 text-center rounded __text font-medium text-lg">
              {t("1_7569")}
            </div>
          </Link>
        </div>
      )}
      <div className={isNav ? "overflow-y-auto __scroll nav-menu" : ""}>
        <div className="flex flex-col py-4 text-sm">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children, tag } = item
              if (children && children.length > 0) {
                return <MenuItem {...item} key={url} location={location} isNav={isNav} t={t} />
              } else {
                const activeClassName = location.pathname.includes(url)
                  ? "bg-white  __text"
                  : "text-slate-200"
                let __url = tag === "ams" ? convertLinkAms(url) : url
                return (
                  <li key={url} className="px-4 mb-2">
                    <NavLink to={__url}>
                      <div className={`${activeClassName} menu__item relative`}>
                        {icon}
                        <span className="text-ellipsis font-medium flex-auto overflow-hidden whitespace-nowrap">
                          {t(title)}
                        </span>
                        {!isNav && (
                          <div className="tooltip_title_not_sub hidden text-gray-50 absolute left-[72px] bg-gray-800 p-3 rounded top-0 w-max">
                            {t(title)}
                          </div>
                        )}
                      </div>
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function MenuItem({ title, icon, url, children, location, isNav, closeSidebar, t, tag }) {
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)))
  function onTogle() {
    setOpen((c) => !c)
  }
  const activeClassname = location.pathname.includes(url) ? "bg-white/10 text-slate-200" : ""
  const classIsDown = isOpen ? "rotate-180" : "rotate-0"

  return (
    <div className="sub__menu relative">
      <li className="px-4">
        <div
          onClick={onTogle}
          className={`${activeClassname} rounded cursor-pointer hover:bg-white/20
        h-11 px-4 flex items-center justify-between gap-4`}
        >
          <div className="flex w-full items-center gap-4">
            {icon}
            <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
              {t(title)}
            </span>
          </div>
          {isNav && (
            <div className={`${classIsDown} flex items-center transition-all duration-300`}>
              {icDown}
            </div>
          )}
        </div>
      </li>
      <div className="h-[1px]"></div>
      <Collapse isOpen={isOpen} isShow={isNav}>
        <ul className="p-2 space-y-2 bg-white/10 rounded child-menu">
          {children.map((item) => {
            const _url = tag === "ams" ? convertLinkAms(item.url) : item.url
            return (
              <li key={`${item.url}-${url}`} className="font-medium">
                <NavLink
                  to={`${_url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex bg-white  items-center __text gap-4 py-4 px-4 rounded __active__sub__menu"
                      : "flex items-center text-slate-200 gap-4 py-4 px-4 rounded hover:text-white hover:bg-white/20"
                  }
                >
                  <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                    {item.icon}
                    {t(item.title)}
                  </span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

const icDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
)
